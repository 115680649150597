header {
    height: 100vh;
    padding-top: 5rem;
    overflow: hidden;
}

.header_container {
    text-align: center;
    height: 100%;
    position: relative;
}

/* ============= CTA ================ */
.cta {
    margin-top: 1.5rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;
}

/* ================= HEADER SOCIALS =============== */
.header_socials {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: .8rem;
    position: absolute;
    left: 0;
    bottom: 3rem;
}

.header_socials a {
    color: var(--color-primary);
}

.header_socials::after {
    content: "";
    width: 1px;
    height: 2rem;
    background: var(--color-primary);
}


/* ===============  ME  ================== */
.me {
        width: 22rem;
        height: 30rem;
        position: absolute;
        opacity: 0.6;
        left: calc(50% - 11rem);
        margin-top: 0rem;
        border-radius: 12rem 12rem 0 0;
        overflow: hidden;
        padding: 3rem 2.5rem 2.5rem 2.5rem;
        -webkit-animation: mover 1.3s infinite alternate;
}



@-webkit-keyframes mover {
    0% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(-20px);
    }
}

@keyframes mover {
    0% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(-20px);
    }
}

/* ==================== SCROLL DOWN ==================== */
.scroll_down {
    position: absolute;
    right: -2.3rem;
    bottom: 5rem;
    transform: rotate(90deg);
    font-weight: 200;
    font-size: 0.9rem;
    color: var(--color-primary);
}

/* ================ MEDIA QUERIES (MEDIUM DEVICES) =================== */
@media screen and (max-width: 1024px) {
        header {
            height: 98vh;
        }

        .header_container h1 {
            font-size: 4rem;
        }

.me {
        display: flex;
        flex-direction: column;
        }
    
}

/* ==================== MEDIA QUERIES (SMALL DEVICES) ======================= */
@media screen and (max-width: 700px) {
    header {
            height: 100vh;
    }

    .header_container h1 {
                    font-size: 2.2rem;
                    padding: .1rem;
                }

    .header_socials,
    .scroll_down {
    display: none;
    }

    .me {
        display: flex;
        flex-direction: column;
    }

}



