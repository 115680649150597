.trainings_container {
    text-align: center;
    display: grid;
}

.trainings_container > div {
    min-width: auto;
    border-radius: 2rem;
    background: linear-gradient(45deg,
            transparent,
            var(--color-primary),
            transparent);
    display: grid;
    place-items: center;
    background-color: var(--color-bg-variant);
    padding: 2.4rem 5rem;
    border: 1px solid transparent;
    transition: var(--transition);
    margin: 0 auto;

}

.trainings_container>div:hover {
    background: transparent;
    border-color: #FF8c00;
    cursor: default;
}

.trainings_container>div h3 {
    text-align: center;
    margin-bottom: 2rem;
    color: var(--color-primary);
}