.experience_container {
    text-align: center;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
}

.experience_container > div {
    width: 100%;
        aspect-ratio: 1.3/1;
        border-radius: 2rem;
        background: linear-gradient(45deg,
                transparent,
                var(--color-primary),
                transparent);
        display: grid;
        place-items: center;
        opacity: 0.65;
    background-color: var(--color-bg-variant);
    padding: 2.4rem 5rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
}

.experience_container > div:hover {
    background: transparent;
    border-color: #FF8c00;
    cursor: default;
}

.experience_container > div h3 {
    text-align: center;
    margin-bottom: 2rem;
    color: var(--color-primary);
}

/* ================ MEDIA QUERIES (MEDIUM DEVICES) =================== */
@media screen and (max-width: 1024px) {
    .experience_container {
        grid-template-columns: 1fr;
    }

    .experience_container > div {
        width: 80%;
        padding: 2rem;
        margin: 0 auto;
    }
}

/* ==================== MEDIA QUERIES (SMALL DEVICES) ======================= */
@media screen and (max-width: 600px) {
    .experience_container {
        gap: 1rem;
    }

    .experience_container > div {
        width: 100%;
        padding: 2rem 1rem;
    }
}