@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap");

* {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
}

:root {
    --color-bg: #080808;
    --color-bg-variant: #520202;
    --color-primary: #af1f1f;
    --color-primary-variant: #af1f1f;
    --color-white: #fff;
    --color-light: rgba(240, 64, 64, 0.6);

    --transition: all 400ms ease;

    --container-width-lg: 75%;
    --container-width-md: 86%;
    --container-width-sm: 90%;
}

html {
    scroll-behavior: smooth;
}

::-webkit-scrollbar {
    display: none;
}

body {
    font-family: "Poppins", sans-serif;
    background: var(--color-bg);
    color: var(--color-white);
    line-height: 1.7;
    background-image: url(../src/assets/dark-dotted-2.png);
}

/* ================= GENERAL STYLES ================*/
.container {
    width: var(--container-width-lg);
    margin: auto;
}

h2, h3, h4, h5 {
    font-weight: 500;
}

h1{
    font-size: 4.5rem;
}

h5 {
    font-size: .8rem;
    font-weight: 100;
}

p {
    margin-top: -30px;
}

section {
    margin-top: 8rem;
}

section > h2, section > h5 {
    text-align: center;
    color: var(--color-primary);
}

section > h2 {
    color: var(--color-white);
    margin-bottom: 3rem;
}

section > h5 {
    color: white;
}

.text-light {
    color: var(--color-light);
}

a {
    color: black;
    transition: var(--transition);
}

a:hover {
    color: var(--color-white);
}

.btn {
    width: max-content;
    display: inline-block;
    color: var(--color-primary);
    padding: 0.75rem 1.2rem;
    border-radius: 0.4rem;
    cursor: pointer;
    border: 1px solid var(--color-primary);
    transition: var(--transition);
}

.btn:hover {
    background: var(--color-white);
    color: var(--color-bg);
    border-color: transparent;
}

.btn-primary {
    width: max-content;
    background: var(--color-primary);
    color: white;
}

img {
    display: block;
    width: 100%;
    object-fit: cover;
}

/* ======================= MEDIA QUERIES (MEDIUM DEVICES) ==================================*/
@media screen and (max-width: 1024px) {
    .container {
        width: var(--container-width-md);
    }


section {
    margin-top: 6rem;
}
}

/* ============================ MEDIA QUERIES (SMALL DEVICES) ==============================*/
 @media screen and (max-width: 600px) {
    .container {
        width: var(--container-width-sm);
     }

     h1 {
        font-size: 20px;
     }

     h2 {
        font-size: 14px;
     }

    section > h2 {
        margin-bottom: 2rem;
    }

    .btn {
        width: 130px;
        font-size: 10px;
        
    }

    .me {
        width: 22rem;
        position: var(--container-width-sm);
        margin-top: -42px;
    }

}
